import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import WishList from "../components/WishList";
import Breadcrumb from "../components/Breadcrumb";

class Page extends Component {
  render() {
    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: "/wish-list/",
        }}
        path={"/wish-list/"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="page"
          current={{
            title: "Wish List",
            slug: "wishlist",
            uri: "/wishlist",
          }}
        />
        <WishList products={this.props.data.allWpProduct.edges} />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allWpProduct {
      edges {
        node {
          id
          slug
          title
          uri
          product {
            productGallery {
              image
            }
            description
            code
            memberPrice
            tradePrice
            rrpPrice
            siteReady
            techSpec {
              title
              value
            }
          }
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page;
