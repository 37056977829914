import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import Form from './Form';
import { removeFromWishlist } from '../../app/actions';
import { isBrowser, priceFormatter } from '../../helpers';
import { CoreHeadingBlock } from '../blocks/CoreHeadingBlock';
import { isLoggedIn, getUser } from '../../services/auth';

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromWishlist: item => dispatch(removeFromWishlist(item))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Wishlist extends React.Component {
  constructor(props) {
    super(props);

    this.wishlistHandler = this.wishlistHandler.bind(this);
    this.state = {
      group: null,
      authenticated: undefined
    }

    isBrowser && this.state.authenticated === undefined && getUser().token && this.handleAuthentication();
  }


  async handleAuthentication() {
      const response = await isLoggedIn();

      if (response.data.errors) {
        this.setState({
          authenticated: false
        })
      } else {
        this.setState({
          authenticated: response.data.data.user
        })

        const group = response.data.data.user.userGroup.group.slug;

        if (group) {
          this.setState({
            group
          })
        }
      }
  }

  componentDidUpdate(prevProps) {
    if (isBrowser) {
      if (prevProps.wishlist !== this.props.wishlist) {
        Cookies.set('wishlist', this.props.wishlist);
      }
    }
    
  }

  wishlistHandler(id) {
    const { wishlist, removeFromWishlist } = this.props;

    if (wishlist.includes(id)) {
      removeFromWishlist(id);
    }
  }

  render() {
    const { products, wishlist, classes } = this.props;
    const group = this.state.group;

    const filteredProducts = products
    .filter(product => wishlist.includes(product.node.id))
    .map(product => product.node);

    const price = () => {
      if (group === 'member') return 'memberPrice';
      else if (group === 'trade') return 'tradePrice';
      else return false;
    };

    let subtotal = filteredProducts.map(product => product.product[price()]);
    subtotal = subtotal.length ? subtotal.reduce((a, b) => Number(a) + Number(b)) : subtotal;

    if (filteredProducts.length <= 0 || !filteredProducts) {
      return null;
    }

    return <div className={classes.wishlist}>
    <CoreHeadingBlock
      attributes={{
        align: "",
        anchor: "",
        className: classes.header,
        content: "Wishlist",
        level: 1,
        textColor: "primary",
        backgroundColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]} 
      />
    <section className={`${classes.productListing}`}>
      <div className="listing">
        <div className="top-bar">
          <h3>Product</h3>
          {price() && <h3 className="price">Unit Price</h3>}
        </div>
        <div className="products">
          {filteredProducts.map(product => {
            const images = product.product.productGallery;
            return <div className={classes.product} key={product.slug}>
              <button onClick={(e) => this.wishlistHandler(product.id)}>x</button>
              <Link to={product.uri} style={{textDecoration: 'none'}}>
                <img src={ images && images[0].image } alt={product.title} className="placeholder" />
              </Link>
              <Link to={product.uri} style={{textDecoration: 'none'}} className="product-info-link">
                <span className="product-info">
                  <span className="title">{ product.title }</span>
                  <span className="code">{ product.product.code }</span>
                  {price() && <span className="price"><span>Unit Price: </span>{priceFormatter.format(product.product[price()])}</span>}
                </span>
              </Link>
            </div>
          })}
        </div>
        {!!subtotal && <div>
          <p className="total">Total <span>{priceFormatter.format(subtotal)}</span></p>
        </div>}
      </div>
      <Form products={filteredProducts} />
    </section>
    {!filteredProducts.length && <section>
      No products added
    </section>}
  </div>
  }
  

  
}

export default withStyles(styles)(connector(Wishlist));