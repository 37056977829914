export default (theme) => ({
  wishlist: {
    marginBottom: 36,
  },
  header: {
    marginTop: 40,
    marginBottom: 24,
  },
  button: {
    display: "flex",
    margin: "24px 0 24px auto",
  },
  productListing: {
    backgroundColor: "white",
    boxShadow: "0 3px 5px rgba(0,0,0,0.16)",
    "& .listing, & .enquiry-form": {
      padding: 32,
      "& .top-bar": {
        "&::after": {
          content: '""',
          display: "table",
          clear: "both",
        },
        "& h3": {
          fontSize: 16,
          marginTop: 0,
          fontWeight: "normal",
          letterSpacing: "0.016em",
          [theme.breakpoints.up("md")]: {
            float: "left",
          },
          "&.price": {
            display: "none",
            [theme.breakpoints.up("md")]: {
              display: "block",
              float: "right",
            },
          },
        },
      },
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& .listing": {
        flex: "0 0 62.5%",
        padding: 80,
      },
      "& .enquiry-form": {
        flex: "0 0 37.5%",
        padding: 80,
      },
    },
    "& .products": {
      borderTop: "1px solid " + theme.palette.primary.main + "4D",
    },
    "& .actions": {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginTop: 12,
      textAlign: "right",
      "& .download": {
        borderRadius: 26,
        fontFamily: theme.bodyFont,
        fontSize: 18,
        letterSpacing: "0.008em",
        lineHeight: 2,
        fontWeight: 700,
        border: "none",
        padding: "10px 24px",
        marginTop: 24,
        marginRight: 24,
        position: "relative",
        display: "inline-table",
        textDecoration: "none",
        height: 52,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
        transition: "background-color .3s ease-in-out",
        "&::after": {
          content: '""',
          display: "inline-block",
          marginLeft: 12,
          width: 22,
          verticalAlign: "text-top",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 22,
        },
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    "& .enquiry-form": {
      position: "relative",
      backgroundColor: theme.palette.primary.main,
      "& h2, & p": {
        marginBottom: 24,
        color: "white",
      },
      "& .required": {
        textTransform: "uppercase",
        marginBottom: 0,
        fontSize: 12,
        letterSpacing: "0.0152em",
      },
      "& fieldset": {
        border: "none",
        padding: 0,
        "& label": {
          color: theme.palette.primary.contrastText,
          fontSize: 12,
          lineHeight: 1.6,
          letterSpacing: "0.0152em",
          textTransform: "uppercase",
          margin: 0,
          fontFamily: theme.bodyFont,
        },
        " & input, textarea": {
          fontFamily: theme.bodyFont,
          backgroundColor: "transparent",
          borderRadius: 20,
          fontSize: 12,
          lineHeight: 2,
          padding: "7px 20px",
          border: "1px solid " + theme.palette.primary.contrastText,
          marginBottom: 24,
          color: theme.palette.primary.contrastText,
          outline: "none",
        },
        "& textarea": {
          backgroundColor: "white",
          color: theme.palette.primary.main,
        },
        '& input[type="submit"]': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          fontFamily: theme.headerFont,
          fontSize: 18,
          width: "100%",
          display: "block",
          margin: "0 0 0 auto",
          padding: 14,
          transition: "background-color .3s ease-in-out",
          letterSpacing: "0.016em",
          textAlign: "center",
          backgroundPosition: "center right 24px",
          backgroundRepeat: "no-repeat",
          outline: "none",
          boxShadow: "0 8px 10px rgb(0 0 0 0.8)",
          cursor: "pointer",
          borderRadius: 32,
          border: "none",
          "&:hover, &:focus": {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
      },
    },
    "& .total": {
      fontFamily: "Roboto Condensed",
      fontSize: 24,
      lineHeight: "40px",
      textTransform: "uppercase",
      display: "flex",
      justifyContent: "space-between",
      "& span": {
        fontFamily: theme.headerFont,
        color: theme.palette.primary.main,
        fontSize: 32,
        letterSpacing: "0.016em",
      },
    },
  },
  product: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    borderBottom: "1px solid " + theme.palette.primary.main + "4D",
    padding: "40px 0",
    justifyContent: "space-between",
    "& > button": {
      width: 22,
      height: 22,
      border: "none",
      outline: "none",
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main + "14",
      fontFamily: theme.bodyFont,
      color: theme.palette.secondary.main,
      lineHeight: "22px",
      padding: 0,
      textAlign: "center",
      marginRight: 12,
    },
    "& img": {
      width: 80,
      [theme.breakpoints.up("md")]: {
        width: 130,
      },
    },
    "& .placeholder": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .product-info-link": {
      flex: "0 0 calc(100% - 200px)",
      maxWidth: "calc(100% - 200px)",
      paddingLeft: 12,
    },
    "& .product-info": {
      [theme.breakpoints.up("md")]: {
        position: "relative",
        paddingRight: 80,
        display: "block",
      },
      "& .title, & .price": {
        fontFamily: theme.headerFont,
        lineHeight: 1,
        fontSize: 24,
        color: theme.palette.primary.main,
        display: "block",
        letterSpacing: "0.016em",
      },
      "& .code": {
        color: theme.palette.common.black,
        fontFamily: theme.headerFont,
        fontSize: 16,
        letterSpacing: "0.016em",
        lineHeight: 1.5,
        display: "block",
        marginTop: 5,
      },
      "& .price": {
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          top: 0,
          right: 0,
          "& span": {
            display: "none",
          },
        },
      },
    },
  },
  imageLinkRow: {
    marginBottom: 36,
    marginTop: 36,
    [theme.breakpoints.up("md")]: {
      paddingBottom: 80,
    },
  },
});
